import ContactAddressChoice from 'models/agency-choice.model';
import { DeliveryPrice } from 'models/offer-option.model';
import { PaymentChoice } from 'models/payment-choice.model';
import { RecapChoice } from 'models/recap-choice.model';
import UserType from '../../models/UserType.model';
import { DeliveryDetail } from '../../models/delivery-detail-form.model';
import { ColiSportSteps } from '../../models/global';
import RevolutOrderDto from '../../models/revolut-order-dto.model';

export type StepsFormState = {
  steps: ColiSportSteps;
  maxStepReached: number;
  contactAddressChoice: ContactAddressChoice;
  offerOptions: DeliveryPrice[];
  trackingNumber: string;
  colisportID: string;
  requestID: string;
  requestAccountType: string;
  order: RevolutOrderDto;
  paymentChoice: PaymentChoice;
  recapChoice: RecapChoice;
  validityCheck: boolean;
  offerChange: boolean;
  // TODO: This needs to be removed from here!! it doesn't make sense for this state to exist
  repeatOrder: DeliveryDetail;
};

export const initialState: StepsFormState = {
  steps: {
    deliveryDetail: {
      userType: UserType.sender,
      destinationDepartment: {
        code: '',
        name: '',
        fullName: '',
        departmentCode: '',
        postalCode: '',
      },
      departureExtraInfo: '',
      destinationExtraInfo: '',
      departureDepartment: {
        code: '',
        name: '',
        fullName: '',
        departmentCode: '',
        postalCode: '',
      },
      isIndividualAccountType: true,
      departureCountry: 'France',
      destinationCountry: 'France',
      packages: [],
    },
    deliveryPrice: {
      priceWithoutVat: 0,
      vatValue: 0,
      price: 0,
      type: '',
      id: '',
      transporter: '',
      pickUpDateRange: {
        startDate: '',
        endDate: '',
        hasPeriodSelection: false,
        exceptions: [],
      },
      productCode: '',
    },
    assuranceDetail: {
      hasAssuranceAnnulation: false,
      packagesAssurance: [],
      hasOnlyValidPackages: false,
      shouldAssure: false,
      totalCost: 0,
    },
    contactAddresses: {
      senderDetails: null,
      receiverDetails: null,
      isNewAddress: false,
      newAddress: null,
    },
    senderOneWay: {
      isPointRelais: true,
    },
    receiverOneWay: {
      isPointRelais: false,
    },
    deliveryOneWayAgencySchedule: null,
  },
  maxStepReached: 1,
  offerOptions: [],
  offerChange: false,
  contactAddressChoice: {
    hasReceiverChosenAgency: false,
    hasSenderChosenAgency: false,
    hasReceiverRecordedAgency: false,
    hasSenderRecordedAgency: false,
    hasReceiverRecordedNewAddress: false,
    hasSenderRecordedNewAddress: false,
    hasUserRecordedDetails: false,
    hasSenderChosenChange: false,
    hasReceiverChosenChange: false,
    senderContactGeodis: null,
    senderAgency: null,
    receiverContactGeodis: null,
    receiverAgency: null,
    shouldPrefillReceiverContactName: true,
    shouldPrefillSenderContactName: true,
  },
  trackingNumber: '',
  colisportID: '',
  requestID: '',
  requestAccountType: 'particular',
  order: undefined,
  paymentChoice: {
    isDeferredPayment: false,
    isRevolut: false,
    isStripeSepaPayment: false,
  },
  repeatOrder: {
    userType: UserType.sender,
    isIndividualAccountType: true,
    departureCountry: undefined,
    departureDepartment: undefined,
    destinationDepartment: undefined,
    destinationCountry: undefined,
    destinationExtraInfo: undefined,
    departureExtraInfo: undefined,
    packages: undefined,
  },
  recapChoice: {
    finalPrice: 0,
  },
  validityCheck: false,
};

export const actions = {
  UPDATE_STEPS: 'UPDATE_STEPS',
  UPDATE_MAX_STEPS_REACHED: 'UPDATE_MAX_STEPS_REACHED',
  UPDATE_CONTACT_ADDRESS_CHOICE: 'UPDATE_CONTACT_ADDRESS_CHOICE',
  UPDATE_OFFER_OPTIONS: 'UPDATE_OFFER_OPTIONS',
  UPDATE_TRACKING_NUMBER: 'UPDATE_TRACKING_NUMBER',
  UPDATE_COLISPORT_ID: 'UPDATE_COLISPORT_ID',
  UPDATE_REQUEST_ID: 'UPDATE_REQUEST_ID',
  UPDATE_REQUEST_ACCOUNT_TYPE: 'UPDATE_REQUEST_ACCOUNT_TYPE',
  UPDATE_ORDER: 'UPDATE_ORDER',
  UPDATE_PAYMENT_CHOICE: 'UPDATE_PAYMENT_CHOICE',
  UPDATE_RECAP_CHOICE: 'UPDATE_RECAP_CHOICE',
  UPDATE_VALIDITY_CHECK: 'UPDATE_VALIDITY_CHECK',
  UPDATE_OFFER_CHANGE: 'UPDATE_OFFER_CHANGE',
  ON_REPEAT_ORDER: 'ON_REPEAT_ORDER',
  RESET_FORM: 'RESET_FORM',
};

export const stepsFormReducer = (
  state: StepsFormState,
  action: {
    type: string;
    payload?:
      | ColiSportSteps
      | number
      | ContactAddressChoice
      | DeliveryPrice[]
      | string
      | RevolutOrderDto
      | DeliveryDetail
      | RecapChoice
      | PaymentChoice
      | boolean
      | StepsFormState;
  },
) => {
  switch (action.type) {
    case actions.UPDATE_STEPS:
      // eslint-disable-next-line no-case-declarations
      const actionSteps = action.payload as ColiSportSteps;
      return {
        ...state,
        steps: {
          ...state.steps,
          ...actionSteps,
        },
      };
    case actions.UPDATE_MAX_STEPS_REACHED:
      return {
        ...state,
        maxStepReached: action.payload,
      };
    case actions.UPDATE_CONTACT_ADDRESS_CHOICE:
      // eslint-disable-next-line no-case-declarations
      const contactAddChoice = action.payload as ContactAddressChoice;
      return {
        ...state,
        contactAddressChoice: {
          ...state.contactAddressChoice,
          ...contactAddChoice,
        },
      };
    case actions.UPDATE_OFFER_OPTIONS:
      return {
        ...state,
        offerOptions: action.payload,
      };
    case actions.UPDATE_TRACKING_NUMBER:
      return {
        ...state,
        trackingNumber: action.payload,
      };
    case actions.UPDATE_COLISPORT_ID:
      return {
        ...state,
        colisportID: action.payload,
      };
    case actions.UPDATE_REQUEST_ID:
      return {
        ...state,
        requestID: action.payload,
      };
    case actions.UPDATE_REQUEST_ACCOUNT_TYPE:
      return {
        ...state,
        requestAccountType: action.payload,
      };
    case actions.UPDATE_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case actions.UPDATE_PAYMENT_CHOICE:
      // eslint-disable-next-line no-case-declarations
      const payment = action.payload as PaymentChoice;
      return {
        ...state,
        paymentChoice: {
          ...state.paymentChoice,
          ...payment,
        },
      };
    case actions.ON_REPEAT_ORDER:
      return {
        ...state,
        repeatOrder: action.payload,
      };
    case actions.UPDATE_VALIDITY_CHECK:
      return {
        ...state,
        validityCheck: action.payload,
      };
    case actions.UPDATE_OFFER_CHANGE:
      return {
        ...state,
        offerChange: action.payload,
      };
    case actions.UPDATE_RECAP_CHOICE:
      return {
        ...state,
        recapChoice: action.payload,
      };
    case actions.RESET_FORM:
      // eslint-disable-next-line no-case-declarations
      const form = action.payload as StepsFormState;
      return action.payload
        ? {
            ...state,
            ...form,
          }
        : {
            ...initialState,
          };
    default:
      return state;
  }
};
